import React, { createContext, useState, Dispatch, SetStateAction, ReactNode, useEffect } from 'react'
import { IFRAME_BREAKPOINT } from '../config/iframeWidth'

export type IsAutoPlayContextType = {
  isAutoPlay: boolean
  startButton: boolean
  setStartButton: Dispatch<SetStateAction<boolean>>
  setIsAutoPlay: Dispatch<SetStateAction<boolean>>
  autoPlayTimer: number | null
  setAutoPlayTimer: Dispatch<SetStateAction<number | null>>
  skipEntrance: string | boolean
  setSkipEntrance: Dispatch<SetStateAction<string | boolean>>
}

export const IsAutoPlaqyContext = createContext<IsAutoPlayContextType>({} as IsAutoPlayContextType)

export const IsAutoPlaqyProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [isAutoPlay, setIsAutoPlay] = useState(false) // AutoPlay再生か
  const [startButton, setStartButton] = useState(false)
  const [autoPlayTimer, setAutoPlayTimer] = useState<number | null>(null) // AutoPlay再生用タイマー
  const [skipEntrance, setSkipEntrance] = useState<string | boolean>(false)
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const isSkipEntrance =
      searchParams.get('skip-entrance') ?? (window?.innerWidth > IFRAME_BREAKPOINT.SM && window.top !== window.self)
    setSkipEntrance(isSkipEntrance)
  }, [])
  return (
    <IsAutoPlaqyContext.Provider
      value={{
        isAutoPlay,
        setIsAutoPlay,
        autoPlayTimer,
        setAutoPlayTimer,
        setStartButton,
        startButton,
        setSkipEntrance,
        skipEntrance,
      }}
    >
      {children}
    </IsAutoPlaqyContext.Provider>
  )
}
