import React from 'react'
import { Box, Text, Link, HStack } from '@chakra-ui/react'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { SIDEBAR_COLOR_THEME } from '../../config/sidebarColorTheme'
import { SidebarColorTheme } from '../../types/sidebarColorTheme'
import { usePlayerData } from '../../hooks/usePlayerData'

const CookieBanner = ({ handleAccept, handleReject, handleManage }: any) => {
  const { t, i18n } = useTranslation()
  const { sidebarColorTheme } = usePlayerData()
  const parts = t('cookie.description').split(t('cookie.splitPointer'))
  const getCookieBannerStyles = (sidebarColorTheme: SidebarColorTheme) => {
    switch (sidebarColorTheme) {
      case SIDEBAR_COLOR_THEME.BLACK:
        return {
          bgColorIcon: 'gray.800',
          contentColor: 'white',
          hoverIconColor: 'gray.700',
          bgColor: '#1A1A1A',
          customizeSettingColor: 'white',
          customizeButtonHoverColor: '#ffffff1a',
          buttonColor: 'solid',
        }
      case SIDEBAR_COLOR_THEME.WHITE:
        return {
          bgColorIcon: 'white.800',
          contentColor: 'black',
          hoverIconColor: 'gray.300',
          bgColor: 'gray.200',
          customizeSettingColor: 'black',
          customizeButtonHoverColor: 'gray.300',
          buttonColor: 'black',
        }
      case SIDEBAR_COLOR_THEME.GRAY:
        return {
          bgColorIcon: 'gray.200',
          contentColor: 'black',
          hoverIconColor: 'white.900',
          bgColor: 'gray.400',
          customizeSettingColor: 'black',
          customizeButtonHoverColor: 'white.200',
          buttonColor: 'black',
        }
      default:
        return {
          bgColorIcon: '#2067E3',
          contentColor: 'white',
          hoverIconColor: '#4A8DF2',
          bgColor: '#1A1A1A',
          customizeSettingColor: 'rgba(142, 194, 245, 1)',
          customizeButtonHoverColor: '#ffffff1a',
          buttonColor: 'solid',
        }
    }
  }
  const currentStyle = getCookieBannerStyles(sidebarColorTheme)

  return (
    <Box
      position="fixed"
      bottom={0}
      width={'100%'}
      bg={currentStyle.bgColor}
      color={currentStyle.contentColor}
      padding={6}
      boxShadow="lg"
      zIndex="cookieModal"
      height={{ sm: 'fit-content', md: '160px' }}
    >
      <Box maxW="977px">
        <Text
          fontSize="20px"
          fontWeight="semibold"
          mb={4}
          lineHeight="28px"
          fontFamily="Montserrat"
          letterSpacing="-0.01em"
        >
          {t('cookie.title')}
        </Text>
      </Box>
      <HStack
        justifyContent="space-between"
        flexDirection={{ base: 'column', md: 'row' }}
        gap={{ base: '16px', md: '0' }}
      >
        <Box width={{ base: '100%', md: '977px' }} height={{ base: 'fit-content', md: '48px' }}>
          <Text fontSize="14px" fontWeight="normal" fontFamily="lato" lineHeight="24px">
            {parts.map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < parts.length - 1 && (
                  <Link
                    href={
                      i18n.language === 'ja'
                        ? 'https://hello.whereness.io/ja/privacy-policy'
                        : 'https://hello.whereness.io/en/privacy-policy'
                    }
                    isExternal
                    textDecoration="underline"
                  >
                    {t('cookie.privacyPolicy')}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Text>
        </Box>
        <HStack height="48px" width={{ base: '311px', md: 'auto' }} spacing={{ base: '4px', md: '0' }}>
          <Box
            width={{ base: '101px', md: i18n.language === 'ja' ? '110px' : '179px' }}
            px={1}
            py={1.5}
            height="48px"
            ml={{ base: -2, sm: 0 }}
          >
            <Button
              sx={{
                '&::before': {
                  content: 'none',
                  display: 'none',
                },
              }}
              color="transparent"
              onClick={handleManage}
              width={{ base: '93px', md: i18n.language === 'ja' ? '102px' : '171px' }}
              px={3}
              py={2.5}
              height="36px"
              border="none"
              bg="transparent"
              _hover={{
                //color: '#B0D8FF',
                backgroundColor: currentStyle.customizeButtonHoverColor,
              }}
              _focus={{ boxShadow: 'none' }}
            >
              <Text
                display={{ base: 'none', md: 'block' }}
                color={currentStyle.customizeSettingColor}
                fontFamily="Montserrat, sans-serif"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center"
              >
                {t('cookie.customize')}
              </Text>
              <Text
                display={{ base: 'block', md: 'none' }}
                color={currentStyle.customizeSettingColor}
                fontFamily="Montserrat, sans-serif"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center"
              >
                {t('cookie.customizeSmall')}
              </Text>
            </Button>
          </Box>
          <Box width={{ base: '101px', md: i18n.language === 'ja' ? '109px' : '112px' }} px={1} py={1.5} height="48px">
            <Button
              color={currentStyle.buttonColor}
              onClick={handleReject}
              width={{ base: '101px', md: i18n.language === 'ja' ? '101px' : '104px' }}
              height="36px"
              px={3}
              py={2.5}
              bg={currentStyle.bgColorIcon}
              borderRadius="16px"
              fontFamily="Montserrat, sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              textAlign="center"
              _hover={{ bg: currentStyle.hoverIconColor }}
              _focus={{ boxShadow: 'none' }}
              sx={{
                '&::before': {
                  content: 'none',
                  display: 'none',
                },
              }}
            >
              {t('cookie.reject')}
            </Button>
          </Box>
          <Box width={{ base: '101px', md: '112px' }} px={1} py={1.5} height="48px">
            <Button
              color={currentStyle.buttonColor}
              onClick={handleAccept}
              width={{ base: '101px', md: i18n.language === 'ja' ? '101px' : '104px' }}
              height="36px"
              bg={currentStyle.bgColorIcon}
              px={3}
              py={2.5}
              borderRadius="16px"
              fontFamily="Montserrat, sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              textAlign="center"
              _hover={{ bg: currentStyle.hoverIconColor }}
              _focus={{ boxShadow: 'none' }}
              sx={{
                '&::before': {
                  content: 'none',
                  display: 'none',
                },
              }}
            >
              {t('cookie.accept')}
            </Button>
          </Box>
        </HStack>
      </HStack>
    </Box>
  )
}

export default CookieBanner
