import { lazy, memo, Suspense, useEffect, useState, VFC, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { usePage } from '../../hooks/usePage'
import { useProjectInfo } from '../../hooks/useProjectInfo'
import { Landing } from './Landing'
import { Loading } from './Loading'
import { useSignedCookies } from '../../hooks/useSignedCookies'
import { useAuth } from '../../hooks/useAuth'
import { useListSpace } from '../../hooks/useListSpace'
import { useActiveNumber } from '../../hooks/useActiveNumber'
import { useFirstSceneLoading } from '../../hooks/providers/useFirstSceneLoading'
import { AnimatePresence } from 'framer-motion'
import { useCookie } from '../../hooks/useCookie'
import { usePlayerData } from '../../hooks/usePlayerData'
import { SceneLoading } from '../organisms/SceneLoading'
import { shouldHandleShortcut } from '../../services/shortcut'
import { viewType } from '../../types/playerDataType'
import { VIEW_TYPE } from '../../config/playerData'
import { useMapInfo } from '../../hooks/useMapInfo'
import { useIsAutoPlay } from '../../hooks/providers/useIsAutoPlay'
import { useAudio, useVideo } from '@actualinc/whereness_player_package'
import { useViewingHistory } from '../../hooks/useViewingHistory'
import { IFRAME_BREAKPOINT } from '../../config/iframeWidth'

const Space = lazy(() => import('./Space'))

type PlayerType = {
  type: viewType
}

export const Player: VFC<PlayerType> = memo((props) => {
  const { type } = props
  const { page, setPage } = usePage()
  const { projectPublishId } = useParams() // Project ID
  const [firstLoading, setFirstLoading] = useState(true)
  const [isSpace, setIsSpace] = useState(false)
  const [isReady, setIsReady] = useState({ wait: false, loading: false, landing: false }) // // 初回ローディングのチラつき防止の為、最低1秒待つ
  const { setProjectPublishId, setViewType, viewType, enabledFeatures, sceneInfoData } = usePlayerData()
  const { video } = useVideo()
  const { audio } = useAudio()
  const { addHistory } = useViewingHistory()
  const { activeSceneId } = useActiveNumber()
  const { setActiveNumber } = useActiveNumber()
  const { isFirstSceneLoading, setIsFirstSceneLoading } = useFirstSceneLoading()
  const { fetchAuth } = useAuth()
  const { fetchSignedCookies } = useSignedCookies()
  const { fetchProjectInfo } = useProjectInfo()
  const { fetchListSpace } = useListSpace()
  const { setStartButton, skipEntrance } = useIsAutoPlay()
  const { checkCookie } = useCookie()
  const { fetchMapInfo } = useMapInfo()
  const [isIframe, setIsIframe] = useState(false)
  const hasFetched = useRef(false)

  useEffect(() => {
    setIsIframe(window.top !== window.self)
  }, [setIsIframe])

  useEffect(() => {
    setProjectPublishId(projectPublishId)
    sessionStorage.setItem('projectPublishId', `{"projectPublishId":"${projectPublishId}"}`)

    setViewType(type)
    if (hasFetched.current) {
      console.log("Skipping fetch as it's already been called.")
      return
    }
    // preview時cms_access_tokenのcookieが存在しない場合は、Studioにリダイレクト
    if (type === VIEW_TYPE.PREVIEW && !checkCookie('cms_access_token')) {
      window.location.replace(process.env.REACT_APP_STUDIO_URL)
    }

    hasFetched.current = true

    const getFirstApi = async () => {
      setFirstLoading(true)
      setIsSpace(false)
      setIsFirstSceneLoading(true)

      const resAuth = await fetchAuth({ type: type, projectPublishId: projectPublishId })
      if (resAuth?.data.statusCode !== 200) {
        return
      }

      const resSignedCookies = await fetchSignedCookies()
      if (resSignedCookies?.data.statusCode !== 200) {
        return
      }

      const resProjectInfo = await fetchProjectInfo({ type: type })
      if (resProjectInfo?.data.statusCode !== 200) {
        return
      }

      const resListSpace = await fetchListSpace()
      if (resListSpace?.data.statusCode !== 200) {
        return
      }

      setActiveNumber({ spaceNumber: 0, sceneNumber: 0 }) //現在の番号 に格納

      setPage('landing')

      if (window.top === window.self) {
        // need to add conditional of 480px. maybe through iframe width/ breakpoint
        // need to calculate the conditional directly as useState new value not immediately used
        setIsSpace(true) // Spaceコンポーネント有効
      }
      if (skipEntrance === 'true') {
        setIsSpace(true)
      }
    }
    getFirstApi()
  }, [projectPublishId, type, isIframe])

  useEffect(() => {
    if (!enabledFeatures || !enabledFeatures['2d-map']) {
      return
    }
    if (window?.innerWidth <= IFRAME_BREAKPOINT.XS && isIframe) {
      return
    }
    fetchMapInfo()
  }, [enabledFeatures])

  // 初回Sceneメディアの読み込みが完了
  useEffect(() => {
    setIsReady((prevState) => ({ ...prevState, loading: true })) // ローディング完了
  }, [isFirstSceneLoading])

  useEffect(() => {
    // 初回ローディングのチラつき防止の為、最低数秒待つ
    setTimeout(() => {
      setIsReady((prevState) => ({ ...prevState, wait: true }))
    }, 800)

    // ショートカット設定
    window.addEventListener('keydown', (event) => {
      // フルスクリーン
      if (shouldHandleShortcut(event, 'f')) {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen()
        }
      }
      // フルスクリーン解除
      if (event.key === 'Escape') {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (isReady.wait && isReady.loading && isReady.landing) {
      setFirstLoading(false)
    }
  }, [isReady])

  const onLoadedLanding = () => {
    setIsReady((prevState) => ({ ...prevState, landing: true }))
  }

  useEffect(() => {
    if (!!skipEntrance) {
      if (activeSceneId === null || !sceneInfoData) {
        return
      }
      setStartButton(true)

      setPage('space')
      addHistory(activeSceneId)
    }
  }, [video, audio, activeSceneId, sceneInfoData, isIframe])
  return (
    <>
      <AnimatePresence exitBeforeEnter>{firstLoading && <Loading degree={50} />}</AnimatePresence>
      <Suspense fallback={<SceneLoading isOpenNavigation={true} />}>{isSpace && <Space />}</Suspense>
      <AnimatePresence exitBeforeEnter>
        {page === 'landing' && (
          <Landing onLoaded={onLoadedLanding} setIsSpace={setIsSpace} skipEntrance={skipEntrance} />
        )}
      </AnimatePresence>
    </>
  )
})
