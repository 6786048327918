const tokenKey = window.top !== window.self ? window.location.pathname + '-token' : 'token'

export const getTokenStorage = (name: string): string | null => {
  const data = sessionStorage.getItem(tokenKey)
  if (!data) {
    return null
  }
  const token = JSON.parse(data)
  return token.hasOwnProperty(name) ? token[name] : null
}

export const setTokenStorage = (value: string): void => {
  sessionStorage.setItem(tokenKey, JSON.stringify(value))
}
